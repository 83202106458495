import React, { useEffect, useState } from "react";
import Layout from "../components/layout/index";
import HeroImageSection from "../components/courses/heroImageSection";
import CoursesTabs from "../components/courses/coursesTabs";
import SearchedCoursesTabs from "../components/courses/searchedCourses";
import ChallengesTab from "../components/challenges";
import InstructorsTabs from "../components/courses/instructorTabs";
import Carousel from "../components/courses/carousel";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Grid,
  TextField,
  Divider,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { Search, Close } from "@material-ui/icons";
import CustomButton from "../components/common/button";
import { RELATE_APP_API_URL } from "../utils/constants";
import useFetch from "use-http";
import { graphql } from "gatsby";
import { useLocation } from "@reach/router";
export default function Courses({ data }) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const [configs, setConfigs] = useState(null);
  const [showSearch, setShowSearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState(null);
  const [filteredCategories, setFilteredCategories] = useState(null);
  const [searchedData, setSearchedData] = useState(null);
  const [instructors, setInstructors] = useState(null);
  const [filteredInstructors, setFilteredInstructors] = useState(null);
  const [challenges, setChallenges] = useState([]);
  let timer = 0;

  const seoData = {
    title: data?.strapiCoursesPageInfo?.seo_title,
    description: data?.strapiCoursesPageInfo?.seo_description,
    image: data?.strapiHomeMetaData?.image?.publicURL,
    keyword: data?.strapiCoursesPageInfo?.seo_tags,
    url: "/courses",
  };

  const { get, response } = useFetch(`${RELATE_APP_API_URL}`);
  const handleSearch = (data) => {
    try {
      async function fetchData(term) {
        if (term) {
          const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          };
          setLoading(true);
          await fetch(
            `${RELATE_APP_API_URL}/static/search?type=Course&searchTerm=${term}`,
            requestOptions
          )
            .then((response) => response.json())
            .then((response) => {
              if (response?.data) {
                let arr = [];
                let arr2 = [...response?.data.map((o, i) => o.courses)];

                for (let index = 0; index < arr2?.length; index++) {
                  arr = arr.concat(arr2[index]);
                }

                setSearchedData(arr);
                setFilteredCategories(null);
              }
              setLoading(false);
            });
          await fetch(
            `${RELATE_APP_API_URL}/static/search?type=InstructorOrStudio&searchTerm=${term}`,
            requestOptions
          )
            .then((response) => response.json())
            .then((response) => {
              if (response?.data) {
                setFilteredInstructors(response?.data);
              } else {
                setFilteredInstructors(instructors);
              }
            });
        } else {
          setFilteredCategories(categories);
          setSearchedData(null);
        }
      }
      fetchData(data);
    } catch (e) {}
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    async function fetchConfigs() {
      const _configs = data?.allRestApiApiV1Config?.edges[0]?.node;

      let obj = {};
      for (const item of _configs?.data) {
        obj = { ...obj, [item.key]: item.value };
      }

      setConfigs(obj);
    }

    fetchConfigs();
  }, []);

  useEffect(() => {
    async function fetchCategories() {
      const _categories = await get("/static/coursesGroupByCategory");
      if (response.ok) {
        setCategories(_categories?.data);
      } else {
        fetchCategories();
      }
    }
    async function fetchInstructors() {
      const _instructors = await get("/static/getInstructorsAndStudios");
      if (response.ok) {
        setInstructors(_instructors?.data);
      } else {
        fetchInstructors();
      }
    }

    async function fetchChallenges() {
      const _challenges = await get("/static/getAllChallenges");

      if (response.ok) {
        setChallenges(_challenges?.data);
      } else {
        fetchChallenges();
      }
    }
    fetchChallenges();
    fetchCategories();
    fetchInstructors();
  }, []);

  useEffect(() => {
    if (categories) {
      setFilteredCategories(categories);
    }
  }, [categories]);
  useEffect(() => {
    if (instructors) {
      setFilteredInstructors(instructors);
    }
  }, [instructors]);

  useEffect(() => {
    if (location?.state?.scroll) {
      setTimeout(() => {
        scrollToTeachersDiv();
      }, 500);
    }
  }, []);

  const scrollToTeachersDiv = () => {
    var element = document.getElementById("teachers-div");
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "center",
    });
  };
  const scrollToCoursesDiv = () => {
    var element = document.getElementById("courses-div");
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  return (
    <Layout
      scrollToTeachers={scrollToTeachersDiv}
      scrollToCoursesDiv={scrollToCoursesDiv}
      seoData={seoData}
    >
      {/* <Header /> */}
      <Grid container justify="center" style={{ backgroundColor: "#FBFBFB" }}>
        {/* <Grid item xs={12}>
          <HeroImageSection
            title={data?.strapiCoursesPageInfo?.title}
            image={data?.strapiCoursesPageInfo?.heroImage?.publicURL}
          />
        </Grid> */}
        {/* {!showSearch ? (
          <Grid
            container
            justify="center"
            spacing={isMobile ? 1 : 2}
            style={{ padding: isMobile ? "0px 5px" : "20px 30px" }}
          >
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                height: "70px",
              }}
            >
              <Search />
            </Grid>
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
            container
            justify="center"
            spacing={isMobile ? 1 : 2}
            style={{ padding: isMobile ? "0px 5px" : "20px 30px" }}
          >
            <Grid item xs={9} sm={9} md={9} lg={9}>
              <TextField
                className={classes.searchField}
                type="Search"
                style={{ width: "100%" }}
                placeholder="Search..."
                name="search"
                onChange={(e) => handleSearch(e)}
                InputProps={{
                  endAdornment: <Search />,
                }}
              />
            </Grid>
            <Grid item xs={3} sm={3} md={2} lg={2}>
              <CustomButton btnText="Search" height="58px" />
            </Grid>
          </Grid>
        )} */}
        <Grid
          container
          id={"courses-div"}
          style={{ display: "flex", justifyContent: "center" }}
        >
          {/* <Grid item xs={12} md={6}>
            <Grid
              container
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid item xs={4} md={5}>
                <Divider className={classes.divider} />
              </Grid>
              <Grid
                item
                xs={4}
                md={2}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={"/img/Vector.png"} alt="img" />
              </Grid>
              <Grid item xs={4} md={5}>
                <Divider className={classes.divider} />
              </Grid>
            </Grid>
          </Grid> */}
          {/* {showSearch && ( */}
          <Grid
            item
            xs={12}
            md={6}
            container
            justify="center"
            spacing={isMobile ? 1 : 2}
            style={{
              paddingTop: isMobile ? "5px" : "45px",
              paddingBottom: "0px",
            }}
          >
            <>
              <Grid item xs={8} sm={8} md={9} lg={9}>
                <TextField
                  className={classes.searchField}
                  type="Search"
                  style={{ width: "100%" }}
                  placeholder="Search..."
                  name="search"
                  onChange={(e) => {
                    if (e.target.value.length > 2) {
                      handleSearch(e.target.value);
                    } else if (e.target.value.length == 0) {
                      setFilteredCategories(categories);
                      setSearchedData(null);
                    } else {
                      setFilteredCategories(categories);
                      setSearchedData(null);
                    }
                  }}
                  InputProps={{
                    endAdornment: <Search />,
                  }}
                />
              </Grid>
              <Grid
                item
                xs={4}
                sm={4}
                md={2}
                lg={2}
                style={{ position: "relative" }}
              >
                <div style={{ maxWidth: "95%" }}>
                  <CustomButton btnText="Search" height="55px" />
                </div>
                {/*   <Close
                    style={{
                      position: "absolute",
                      bottom: "75px",
                      left: "85px",
                      cursor: "pointer",
                    }}
                    className={classes.searchIcon}
                    onClick={() => setShowSearch(!showSearch)}
                  /> */}
              </Grid>
            </>
          </Grid>
          {/* )} */}
          <Grid container style={{ marginTop: "2%" }}>
            <Grid item xs={2} />

            <Grid item xs={8}>
              <Typography
                component="h1"
                style={{ fontSize: isMobile && "24px" }}
                className={classes.heading}
              >
                {data?.strapiCoursesPageInfo?.coursesTitle}
              </Typography>
            </Grid>
            {/*  <Grid
              item
              xs={2}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {!showSearch && (
                <Search
                  className={classes.searchIcon}
                  onClick={() => setShowSearch(!showSearch)}
                />
              )}
            </Grid> */}
          </Grid>
          <Grid
            container
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "2%",
            }}
          >
            <Grid
              item
              xs={12}
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: isMobile ? "0px 5px" : "0px 30px",
              }}
            >
              <Typography
                component="div"
                style={{ fontSize: isMobile && "18px" }}
                className={classes.subHeading}
              >
                {data?.strapiCoursesPageInfo?.aboutCourses}
              </Typography>
            </Grid>
          </Grid>

          <Grid container style={{ marginTop: "2%" }}>
            {filteredCategories?.length > 0 ? (
              <CoursesTabs categories={filteredCategories} configs={configs} />
            ) : searchedData && searchedData.length > 0 ? (
              <SearchedCoursesTabs
                configs={configs}
                searchedData={searchedData}
                loading={loading}
              />
            ) : null}
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid
            container
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid item xs={4} md={5}>
              <Divider className={classes.divider} />
            </Grid>
            <Grid
              item
              xs={4}
              md={2}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={"/img/Vector.png"}
                alt="img"
                width="81"
                height="75"
                loading="lazy"
              />
            </Grid>
            <Grid item xs={4} md={5}>
              <Divider className={classes.divider} />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          id={"courses-div"}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Grid container style={{ marginTop: "2%" }}>
            <Typography
              component="h1"
              style={{ fontSize: isMobile && "24px" }}
              className={classes.heading}
            >
              {data?.strapiCoursesPageInfo?.coursesTitle}
            </Typography>
          </Grid>
          <Grid
            container
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "1%",
            }}
          >
            <Grid
              item
              xs={12}
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: isMobile ? "0px 5px" : "0px 30px",
              }}
            >
              <Typography
                component="div"
                style={{ fontSize: isMobile && "18px" }}
                className={classes.challengeHeading}
              >
                Challenges
                {/*    {data?.strapiCoursesPageInfo?.aboutCourses} */}
              </Typography>
            </Grid>
          </Grid>

          <Grid container style={{ marginTop: "3%" }}>
            {
              challenges?.length > 0 && (
                <ChallengesTab
                  challenges={challenges}
                  isHome={true}
                  configs={configs}
                />
              ) /* : (
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Typography component="div" className={classes.noDataMsg}>
                  Loading...
                </Typography>
              </Grid>
            ) */
            }
          </Grid>
        </Grid>
        <Grid
          container
          id={"teachers-div"}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Grid item xs={12} md={6}>
            <Grid
              container
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid item xs={4} md={5}>
                <Divider className={classes.divider} />
              </Grid>
              <Grid
                item
                xs={4}
                md={2}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={"/img/Vector.png"}
                  alt="img"
                  width="81"
                  height="75"
                  loading="lazy"
                />
              </Grid>
              <Grid item xs={4} md={5}>
                <Divider className={classes.divider} />
              </Grid>
            </Grid>
          </Grid>

          <Grid container style={{ marginTop: "2%" }}>
            <Typography
              component="h1"
              style={{ fontSize: isMobile && "24px" }}
              className={classes.heading}
            >
              {data?.strapiCoursesPageInfo?.instructorsTitle}
            </Typography>
          </Grid>
          <Grid
            container
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "2%",
            }}
          >
            <Grid
              item
              xs={12}
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: isMobile ? "0px 5px" : "0px 30px",
              }}
            >
              <Typography
                component="div"
                style={{ fontSize: isMobile && "18px" }}
                className={classes.subHeading}
              >
                {data?.strapiCoursesPageInfo?.aboutInstructors}
              </Typography>
            </Grid>
          </Grid>

          <Grid container style={{ marginTop: "2%" }}>
            {filteredInstructors && (
              <InstructorsTabs
                instructors={filteredInstructors?.instructors}
                studios={filteredInstructors?.studios}
                categories={categories}
              />
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid
            container
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid item xs={4} md={5}>
              <Divider className={classes.divider} />
            </Grid>
            <Grid
              item
              xs={4}
              md={2}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingBotton: "5px",
              }}
            >
              <img
                src={"/img/Vector.png"}
                alt="img"
                width="81"
                height="75"
                loading="lazy"
              />
            </Grid>
            <Grid item xs={4} md={5}>
              <Divider className={classes.divider} />
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Carousel />
        </Grid>
      </Grid>
    </Layout>
  );
}

// export default IndexPage

const useStyles = makeStyles((theme) => ({
  searchIcon: {
    color: "#09A9BB",
    cursor: "pointer",
  },
  searchField: {
    "& .MuiInputBase-root": {
      backgroundColor: "rgba(9, 169, 187, 0.1)",
      // color: "rgba(124, 124, 124, 0.25)",
      color: "#000",
      fontFamily: theme.palette.fontFamily.Reef,
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "21px",
      padding: "10px 30px",
      height: "58px",
      alignSelf: "center",
      position: "static",
      marginBottom: "20px",
      width: "100%",
      marginTop: "50px",
      borderRadius: "10px",
      boxShadow: "inset 0px 6px 6px rgba(0, 65, 72, 0.05)",
      width: "100%",
    },
    "& .MuiBadge-colorPrimary": {
      backgroundColor: theme.palette.secondary.light,
    },

    "& .MuiInput-underline:before": {
      border: "0px solid #FBF1E0",
    },
    "& .MuiInput-underline::after": {
      border: "0px solid #FBF1E0",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
      border: "0px solid #FBF1E0",
    },
  },

  divider: {
    background: theme.palette.primary.light,
    height: "2px",
  },
  heading: {
    textAlign: "center",
    width: "100%",
    fontFamily: theme.palette.fontFamily.Reef,
    fontWeight: 900,
    fontSize: "36px",
    lineHeight: "42.19px",

    fontStyle: "normal",
    color: "#000",
  },
  challengeHeading: {
    textAlign: "center",
    width: "100%",
    fontFamily: theme.palette.fontFamily.Reef,
    fontWeight: 900,
    fontSize: "36px",
    lineHeight: "42.19px",
    fontStyle: "normal",
    textTransform: "uppercase",
    color: "#000",
  },
  subHeading: {
    textAlign: "center",
    width: "100%",
    fontFamily: theme.palette.fontFamily.Reef,
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "36.41px",
    color: "#3F3F3F",

    fontStyle: "normal",
  },
  noDataMsg: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "23.34px",
    fontStyle: "normal",
    marginBottom: "1%",
  },
}));

export const query = graphql`
  {
    strapiCoursesPageInfo {
      aboutCourses
      aboutInstructors
      seo_description
      seo_tags
      seo_title
      heroImage {
        publicURL
      }
      coursesTitle
      instructorsTitle
      title
    }
    allRestApiApiV1Config {
      edges {
        node {
          data {
            label
            value
            createdAt
            id
            key
            updatedAt
          }
        }
      }
    }
  }
`;
